import React from 'react'
import { View, Text, StyleSheet, TouchableOpacity } from 'react-native'
import { appointmentConfirmationStyles } from './appintment-confirmation.styles'
import { appointmentStyles } from '../../screens/appointment/appoinment.styles'
import { cancelAppointmentQuery } from '../../services/axios'
import { notify } from '../../utils/notify'
import { withRouter } from 'react-router-dom'
import { isMobile } from '../../utils/dimensions'

const AppointmentConfirmation = ({ data, history, ocultar }) => {
  const { rut, fecha, hora, sucursal } = data
  const handleSubmit = async () => {
    try {
      await cancelAppointmentQuery(
        '/Client/EliminarAgenda',
        localStorage.getItem('token')
      )
      notify('success', 'Se ha cancelado la cita correctamente')
    } catch (error) {
      console.log(error)
      notify('error', 'No se ha podido cancelar la cita')
    }
  }

  return (
    <View style={appointmentConfirmationStyles.container}>
      <Text style={appointmentConfirmationStyles.title}>
        Usted ya tiene una hora disponible
      </Text>
      <Text style={appointmentConfirmationStyles.info}>
        <span style={{ fontWeight: 'bold', color: '#69A62D' }}>RUT: </span>
        {rut}
      </Text>
      <Text style={appointmentConfirmationStyles.info}>
        <span style={{ fontWeight: 'bold', color: '#69A62D' }}>Fecha: </span>
        {fecha}
      </Text>
      <Text style={appointmentConfirmationStyles.info}>
        <span style={{ fontWeight: 'bold', color: '#69A62D' }}>Hora:</span>{' '}
        {hora}
      </Text>
      <Text style={appointmentConfirmationStyles.info}>
        <span style={{ fontWeight: 'bold', color: '#69A62D' }}>
          Formato de cita:
        </span>{' '}
        {sucursal || ''}
      </Text>
        <View style={ isMobile() ? {}: { flexDirection: 'row',  justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
        
        <TouchableOpacity style={[appointmentConfirmationStyles.cancelButton, isMobile() ? {width:'100%'}: {width:'70%'}]} onPress={handleSubmit} >
        <Text style={appointmentConfirmationStyles.cancelButtonText}> Cancelar cita </Text>
        </TouchableOpacity>
        
        <TouchableOpacity
          style={[appointmentConfirmationStyles.closeButton, isMobile() ? {width:'100%'}: {width:'20%', marginLeft: 10}]}
          onPress={()=>ocultar()}
        >
          <Text style={appointmentConfirmationStyles.closeButtonText}>
            Cerrar
          </Text>
        </TouchableOpacity>
        
      </View>
    </View>
  )
}

export default withRouter(AppointmentConfirmation)
