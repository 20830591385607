import React, { useEffect, useState } from 'react'
import { Text, View, TouchableOpacity, Image } from 'react-native'
import { generalStyles } from '../styles/general.styles'
import { navigationStyles } from '../styles/navigation.styles'
import { withRouter } from 'react-router-dom'
import { isMobile } from '../utils/dimensions'

const Navigation = ({ history, showNavigation, viewNavigationB }) => {
  const [activeLink, setActiveLink] = useState('')

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [menuNavigation, setMenuNavigation] = useState();

  useEffect(()=>{
    if(isMobile()){
      var stylea = generalStyles.navigationContainerResponsive
      setMenuNavigation(
        <View style={[generalStyles.navigationContainerResponsive,{height:window.innerHeight}]}>
      <Text style={navigationStyles.menuNav}> MENU </Text>
      <TouchableOpacity
        onPress={() => { onLinkClick('/mis-movimientos');}}
        style={
          activeLink === '/mis-movimientos'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/mis-movimientos'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Mis movimientos
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => onLinkClick('https://agenda.grupodefensa.cl/')}
        style={
          activeLink === '/agendar-hora'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/agendar-hora'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Agendar hora
        </Text>
      </TouchableOpacity>
      {/* <TouchableOpacity
        onPress={() => onLinkClick('/mis-archivos')}
        style={
          activeLink === '/mis-archivos'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/mis-archivos'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Mis archivos
        </Text>
      </TouchableOpacity> */}
      <TouchableOpacity
        onPress={() => onLinkClick('/mis-datos')}
        style={
          activeLink === '/mis-datos'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/mis-datos'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Mis datos
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => onLinkClick('/estado-de-cuenta')}
        style={
          activeLink === '/estado-de-cuenta'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/estado-de-cuenta'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Estado de cuenta
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => onLinkClick('/club-de-beneficios')}
        style={
          activeLink === '/club-de-beneficios'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/club-de-beneficios'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Club de beneficios
        </Text>
      </TouchableOpacity>
      {/*
      <TouchableOpacity
        onPress={() => onLinkClick('/contacto')}
        style={
          activeLink === '/contacto'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/contacto'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Contáctanos
        </Text>
      </TouchableOpacity>
      */}
      <TouchableOpacity
        onPress={() => onLinkClick('https://pagos.defensadeudores.cl')}
        style={navigationStyles.buttonActiveLink}
      >
        <Text style={navigationStyles.buttonActiveText}>Portal de pagos</Text>
      </TouchableOpacity>
      

      <TouchableOpacity
        onPress={() => onLinkClick('https://api.whatsapp.com/send/?phone=56956079661&text=Hola&type=phone_number&app_absent=0')}
        style={{}}
      >
        <Text style={{}}>Hablemos por WhatsApp</Text>
      </TouchableOpacity>
      
    </View>
      )
    }else{
      setMenuNavigation(
      <View style={generalStyles.navigationContainer}>
      <Text style={navigationStyles.menuNav}> MENU </Text>
      <TouchableOpacity
        onPress={() => onLinkClick('/mis-movimientos')}
        style={
          activeLink === '/mis-movimientos'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
     
        <Text
          style={
            activeLink === '/mis-movimientos'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Mis movimientos
        </Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => onLinkClick('https://agenda.grupodefensa.cl/')}
        style={
          activeLink === '/agendar-hora'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/agendar-hora'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Agendar hora
        </Text>
      </TouchableOpacity>
      {/* <TouchableOpacity
        onPress={() => onLinkClick('/mis-archivos')}
        style={
          activeLink === '/mis-archivos'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/mis-archivos'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Mis archivos
        </Text>
      </TouchableOpacity> */}
      <TouchableOpacity
        onPress={() => onLinkClick('/mis-datos')}
        style={
          activeLink === '/mis-datos'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/mis-datos'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Mis datos
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => onLinkClick('/estado-de-cuenta')}
        style={
          activeLink === '/estado-de-cuenta'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/estado-de-cuenta'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Estado de cuenta
        </Text>
      </TouchableOpacity>
      <TouchableOpacity
        onPress={() => onLinkClick('/club-de-beneficios')}
        style={
          activeLink === '/club-de-beneficios'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/club-de-beneficios'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Club de beneficios
        </Text>
      </TouchableOpacity>
      {/*
      <TouchableOpacity
        onPress={() => onLinkClick('/contacto')}
        style={
          activeLink === '/contacto'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text
          style={
            activeLink === '/contacto'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }
        >
          Contáctanos
        </Text>
      </TouchableOpacity>
      */}
      <TouchableOpacity
        onPress={() => onLinkClick('https://pagos.defensadeudores.cl')}
        style={
          activeLink === '/club-de-beneficios'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text 
          style={
            activeLink === '/club-de-beneficios'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }>Portal de pagos</Text>
      </TouchableOpacity>

      <TouchableOpacity
        onPress={() => onLinkClick('https://api.whatsapp.com/send/?phone=56956079661&text=Hola&type=phone_number&app_absent=0')}
        style={
          activeLink === '/club-de-beneficios'
            ? navigationStyles.buttonActive
            : navigationStyles.button
        }
      >
        <Text 
          style={
            activeLink === '/club-de-beneficios'
              ? navigationStyles.buttonActiveText
              : navigationStyles.buttonText
          }>Hablemos por WhatsApp</Text>
      </TouchableOpacity>
    </View>
      )
    }
  },[])
  
  const onLinkClick = (link) => {
    viewNavigationB()
    setActiveLink(link)
    if (link.startsWith('http')) {
      // Redirige a una URL externa abriendo una nueva pestaña del navegador
      window.open(link, '_blank')
    } else {
      // Redirige a una ruta interna del sitio
      history.push(link)
    }
  }

  return (<>
    {(showNavigation || !isMobile() ) ? menuNavigation : null}
    </>
  )
}

export default withRouter(Navigation)
