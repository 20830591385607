import React, { useState, useEffect } from 'react'
import { View, Button, TouchableOpacity, Text, TextInput, Image, StyleSheet, ImageBackground, Modal, ScrollView } from 'react-native'
import Loading from '../../common/Loading'
import { withRouter } from 'react-router-dom' // Importa withRouter
import homeStyles from './home.styles' 
import {postNoDataQuery} from '../../services/axios'

import { notify } from '../../utils/notify'


const HomeView = ({ history }) => {
    const [nombreCliente,setNombreCliente] = useState();
    const [showDocs,setshowDocs] = useState(false);
    
    useEffect(()=>{
        const info = JSON.parse(localStorage.getItem('informacionCliente'))
        if(info == null) 
        {
            localStorage.clear()
            history.push('/iniciar-sesion')
        }
        else{
            setNombreCliente(info.name.charAt(0).toUpperCase() + info.name.slice(1))
            getDocs()
        }
    },[])

    const getDocs = async () => {
        try {
            const response = await postNoDataQuery( '/Client/Contratos', localStorage.getItem('token') )
            if(response.data.length>0)
                setshowDocs(true)
        } catch (error) {
            console.log(error)
            if(error.response.status==401){
                localStorage.clear()
                history.push('/iniciar-sesion')
            }else{
                const err = error.response
                notify('error', err)
            }
        }
    }

      
 
  return (
    <View style={homeStyles.container}>
        <View style={{width:'100%'}}>
            <View style={homeStyles.textBanner}>
                <Text style={{fontSize:30, color: 'white', fontWeight:'bold', marginBottom:10}}>Hola {nombreCliente}</Text>
                <Text style={{fontSize:16, color: 'white', fontWeight:'bold', marginBottom:5}}>Te damos la bienvenida a la Sucursal Virtual de GrupoDefensa.cl.</Text>
                <Text style={{fontSize:16, color: 'white', fontWeight:'bold', marginBottom:5}}>En tu espacio privado podrás revisar el estado de tu causa, actualizar tu información personal, pagar tu servicio y solicitar información.</Text>
            </View>
            <Image source={require('../../assets/images/banner.jpg')} style={homeStyles.imageBanner} />
        </View>

        <View style={homeStyles.contentCard}>
            <TouchableOpacity style={homeStyles.card} onPress={()=>history.push('mis-movimientos')}>
                <View style={homeStyles.rowContainer}>
                    <Image style={homeStyles.imageWhatsApp} source={require('../../assets/icons/libros.png')}/>
                    <Text style={{fontSize:15, color: 'BLACK', fontWeight:'bold', marginBottom:5}}>REVISA EL ESTADO DE TU CAUSA</Text>
                </View>
                <Text style={homeStyles.bottomText}>Mis movimientos</Text>
            </TouchableOpacity>

            <TouchableOpacity style={homeStyles.card} onPress={()=>window.open('https://agenda.grupodefensa.cl/')}>
                <View style={homeStyles.rowContainer}>
                    <Image style={homeStyles.imageWhatsApp} source={require('../../assets/icons/calendar.png')}/>
                    <Text style={{fontSize:15, color: 'BLACK', fontWeight:'bold', marginBottom:5}}>TIENES DUDAS, AGENDA UNA HORA CON UN ABOGADO</Text>
                </View>
                <Text style={homeStyles.bottomText}>Agendar Hora</Text>
            </TouchableOpacity>
            <TouchableOpacity style={homeStyles.card} onPress={()=>window.open('https://api.whatsapp.com/send/?phone=56956079661&type=phone_number&app_absent=0', '_blank')}>
                <View style={homeStyles.rowContainer}>
                    <Image style={homeStyles.imageWhatsApp} source={require('../../assets/icons/whatsapp.png')}/>
                    <Text style={{fontSize:16, color: 'BLACK', fontWeight:'bold', marginBottom:5, marginTop:2}}>NECESITAS MÁS INFORMACIÓN, HABLEMOS POR WHATSAPP</Text>
                </View>
                <Text style={homeStyles.bottomText}>Ir a WhatsApp</Text>
            </TouchableOpacity>
            
            {showDocs ?
            <TouchableOpacity style={homeStyles.card} onPress={()=>history.push('/mis-documentos')}>
                <View style={homeStyles.rowContainer}>
                    <Image style={homeStyles.imageWhatsApp} source={require('../../assets/icons/contrato.png')}/>
                    <Text style={{fontSize:15, color: 'BLACK', fontWeight:'bold', marginBottom:5}}>REVISA TU CONTRATO AQUI, DESCARGA MI CONTRATO</Text>
                </View>
                <Text style={[homeStyles.bottomText]}>Descargar contrato</Text>
            </TouchableOpacity>
            : null}
        </View>
    </View>
  )
}

export default withRouter(HomeView) // Envuelve el componente Login con withRouter
