import React, { useEffect, useState } from 'react';
import { 
    Modal, 
    View, 
    Text, 
    Button, 
    TouchableWithoutFeedback, 
    ScrollView, 
    ActivityIndicator, 
    Dimensions, 
    TouchableOpacity
} from 'react-native';
import modalLine from '../../assets/images/gd_line.png';
import { Icon } from 'react-native-elements';

const ModalBtn = ({ 
        btnText,
        primaryBtn,
        modalTitle,
        keepLoading,
        optionalLoaderText,
        children,
        closeBtnSibilings,
        onpress,
        forceClose
    }) => {

  const windowWidth = Dimensions.get('window').width;
  const windowHeight = Dimensions.get('window').height;
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(()=> {
    if(forceClose){
        setModalVisible(false)
    }
  }, [forceClose])

  const closeIfNotModal = () => 
    setModalVisible(false)
  

  return (
    <>
        <View
            style={{marginVertical: 5}}
        >
            <Button 
                title={btnText}
                color={(primaryBtn) ? '#1e96f2' : '#018000'}
                onPress={() => {
                    if(!forceClose){
                        setModalVisible(true);
                    }
                    return onpress();
                }}
            />
        </View>

        <Modal
            animationType="fade"
            transparent={true}
            visible={modalVisible}
        >
            <TouchableWithoutFeedback onPress={(e) => closeIfNotModal(e)}>
                <View style={styles.centeredView}>
                    <TouchableWithoutFeedback>
                        <View 
                            style={{ 
                                ...{ maxWidth: windowWidth }, 
                                ...styles.modalView,
                                width: 'clamp(350px, 60vw, 1000px)',
                                minHeight: '40vh',
                                maxHeight: '60vh',
                            }}
                        >
                            <View
                                style={{
                                    flex: 1,
                                    backgroundColor: 'white',
                                    zIndex: 2,
                                    width: '100%',
                                    justifyContent: 'center',
                                }}
                            >
                                <img 
                                    src={modalLine}
                                    style={styles.modalUpperLine}
                                />
                                <Text style={styles.modalTitle}>
                                    {modalTitle}
                                </Text>
                            </View>

                            {
                                (keepLoading)
                                ? 
                                    <View
                                        style={{
                                            marginTop: 50
                                        }}
                                    >
                                        <ActivityIndicator 
                                            size="large" 
                                            color="#69a62d" 
                                            style={{ flex: 15 }} 
                                        />
                                        {
                                            optionalLoaderText
                                            &&
                                            <Text
                                                style={{
                                                    fontFamily: 'Helvetica', 
                                                    fontSize: 19, 
                                                    textAlign: 'center', 
                                                    marginTop: 10
                                                }}
                                            >
                                                {optionalLoaderText}
                                            </Text>
                                        }
                                    </View>
                                : 
                                    <ScrollView
                                        style={{
                                            maxHeight: `${windowHeight}px`,
                                            flex: 15,
                                            paddingLeft: 'clamp(2px, 5vw, 50px)',
                                            paddingRight: 'clamp(2px, 5vw, 50px)',
                                            paddingTop: 50,
                                        }}
                                    >
                                        {children}
                                    </ScrollView>
                            }

                            <View
                                style={{
                                flex: 3,
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                width: '100%',
                                alignItems: 'flex-end',
                                paddingTop: 10
                                }}
                            >
                                <View
                                style={{
                                    width: '100%',
                                    marginTop: 10,
                                    paddingBottom: 20,
                                    paddingHorizontal: 30,
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    gap: 10,
                                }}
                                >
                                <TouchableOpacity
                                    style={{
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        backgroundColor: '#007AFF',
                                        paddingVertical: 10,
                                        paddingHorizontal: 20,
                                        borderRadius: 5,
                                    }}
                                    onPress={() => setModalVisible(false)}
                                >
                                    <Icon
                                        name='close'
                                        color='white'
                                        size={20}
                                        style={{ marginRight: 5 }}
                                    />
                                    <Text
                                        style={{
                                        color: 'white',
                                        fontSize: 'clamp(11px, 1vw, 20px)',
                                        textAlignVertical: 'center',
                                        }}
                                    >
                                        Cerrar
                                    </Text>
                                </TouchableOpacity>
                                {closeBtnSibilings}
                                </View>
                            </View>
                        </View>
                    </TouchableWithoutFeedback>
                </View>
            </TouchableWithoutFeedback>
        </Modal>
    </>
  );
};

const styles = {
    centeredView: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: 'rgba(0, 0, 0, 0.5)'
      },
      modalView: {
        margin: 20,
        backgroundColor: "white",
        borderRadius: 20,
        paddingTop: 0,
        marginTop: 0,
        alignItems: "center",
        shadowColor: "#000",
        shadowOffset: {
          width: 0,
          height: 2,
        },
        shadowOpacity: 0.25,
        shadowRadius: 4,
        elevation: 5,
        width: "90%",
        minHeight: "50vh"
      },
      modalUpperLine: {
        width: '100%',
        borderTopLeftRadius:20,
        borderTopRightRadius:20,
        zIndex: 3
      },
      paragraph: {
        fontFamily: 'Helvetica', 
        fontSize: 19, 
        textAlign: 'center', 
        lineHeight: 28.8, 
        textAlign: 'justify'
      },
      modalTitle: {
        color: '#0076B4',
        backgroundColor: 'white',
        padding: 10,
        paddingTop: 60,
        fontFamily: 'Helvetica', 
        fontSize: 25, 
        textAlign: 'center', 
        lineHeight: 28.8, 
      },
      card: {
        margin: 20,
        backgroundColor: 'white',
        display: 'flex',
      }
};

export default ModalBtn;
