import { StyleSheet } from 'react-native'

export const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: 'rgba(0, 0, 0, 0.5)'
  },
  modalView: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    paddingTop: 0,
    marginTop: 0,
    alignItems: "center",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "90%",
    minHeight: "50vh"
  },
  modalUpperLine: {
    width: '100%',
    borderTopLeftRadius:20,
    borderTopRightRadius:20,
    zIndex: 3
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 10,
  },
  cell: {
    paddingHorizontal: 5,
  },
  header: {
    fontWeight: "bold",
  },
  // Ajuste estos estilos de acuerdo a la longitud de tus datos
  cellEstado: {
    flex: 1,
  },
  cellFechaCreacion: {
    flex: 2,
  },
  cellMensaje: {
    flex: 3,
  },
  noDataText: {
    fontWeight: 'bold',
    fontSize: 18,  
    marginBottom: 20, 
  },
  //nuevos para PJUD
  cellPjud: {
    flex: 1, // Esto asegura que cada celda ocupe el mismo espacio
    padding: 10,
    textAlign: 'center',
    // ... otros estilos
  },
  headerPjud: {
    backgroundColor: 'grey',
    fontWeight: 'bold',
  },
  centeredViewPjud: {
    flex: 1,
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginTop: 22,
  },
  modalViewPjud: {
    margin: 20,
    backgroundColor: "white",
    borderRadius: 20,
    padding: 35,
    alignItems: "flex-start",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
    width: "90%",
  },
  container: {
    flex: 1, 
    justifyContent: 'center', 
    alignItems: 'center',
    padding: 16, 
    marginBottom: '3vh',
    paddingHorizontal: '0px',
  },
  paragraph: {
    fontFamily: 'Helvetica', 
    fontSize: 19, 
    textAlign: 'center', 
    lineHeight: 28.8, 
    textAlign: 'justify'
  },
  modalTitle: {
    color: '#0076B4',
    backgroundColor: 'white',
    padding: 10,
    paddingTop: 60,
    fontFamily: 'Helvetica', 
    fontSize: 25, 
    textAlign: 'center', 
    lineHeight: 28.8, 
  },
  card: {
    margin: 20,
    backgroundColor: 'white',
    display: 'flex',
  },
  cardDateFooter: {
    fontSize: 10
  }


});

