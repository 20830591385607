import React, { useState } from 'react'
import { View, Image, Text } from 'react-native'
import headerStyles from './header.styles'
import { TouchableOpacity } from 'react-native-web'
import { withRouter } from 'react-router-dom'
import { isMobile } from '../../utils/dimensions'

const Header = ({ history, viewNavigation}) => {
  const [isHovered, setIsHovered] = useState(false)


  const handleLogout = () => {
    localStorage.removeItem('token')
    history.push('/iniciar-sesion') 
    return
  }


  const handleMouseEnter = () => {
    setIsHovered(true)
  }

  const handleMouseLeave = () => {
    setIsHovered(false)
  }
  const handleNavigation = ()=>{
    viewNavigation()
  }

  return (
    <View>
      <View style={headerStyles.headerContainer}>
        <View style={headerStyles.leftContainer}>
          {isMobile() ?
          <TouchableOpacity onPress={handleNavigation}>
          <Image source={require('../../assets/images/ico_menu.png')}
            style={headerStyles.iconMenu}
          /> 
          </TouchableOpacity>
          :null}
          <Image
            source={require('../../assets/images/logo_grupodefensa_full.png')}
            style={headerStyles.logo}
          />
          <View style={headerStyles.rightContainer}>
            <View style={headerStyles.socialButtonsContainer}>
              <TouchableOpacity style={headerStyles.button} onPress={handleLogout}>
                <Text style={headerStyles.logout}>Salir</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </View>
    </View>
  )
}

export default withRouter(Header)
