import { View } from 'react-native'
import HeaderPageTitle from '../../common/HeaderPageTitle'
import BenefitsCard from '../../components/benefits-card/BenefitsCard'
import { benefitsStyles } from './benefits-club.styles'
import { withRouter } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getBenefitsQuery } from '../../services/axios'
import Loading from '../../common/Loading'
import { generalStyles } from '../../styles/general.styles'
import isMobile from '../../utils/dimensions'

const BenefitsClub = ({ history }) => {
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/iniciar-sesion') // Ajusta la ruta de inicio de sesión según tu configuración de navegación
    }
  }, [])

  const [benefits, setBenefits] = useState([])
  const [isLoading, setIsLoading] = useState(false) // Estado para controlar el cargando

  useEffect(() => {
    const getBenefits = async () => {
      try {
        setIsLoading(true)
        const response = await getBenefitsQuery(
          '/SucursalVirtual/ClubBeneficios',
          localStorage.getItem('token')
        )
        setBenefits(response.data)
        setIsLoading(false) // Aquí configuras cargando a false
      } catch (error) {
        if(error.response.status==401){
          localStorage.removeItem('token')
          history.push('/iniciar-sesion')
        }else{
          notify('error', error)
          setLoading(false)
        }
      }
      /*
      setIsLoading(true) // Aquí configuras cargando a true
      const response = await getBenefitsQuery(
        '/SucursalVirtual/ClubBeneficios',
        localStorage.getItem('token')
      )
      setBenefits(response.data)
      setIsLoading(false) // Aquí configuras cargando a false
      */
    }
    getBenefits()
  }, [])

  // Muestras el componente de carga si está cargando. Si no, muestras los datos.
  return (
    <View style={(isMobile()?generalStyles.contentContainerResponse:generalStyles.contentContainer)}>
      <HeaderPageTitle title="Beneficios" />
      {isLoading ? (
        <Loading />
      ) : (
        benefits.map((data, index) => <BenefitsCard key={index} data={data} />)
      )}
    </View>
  )
}

export default withRouter(BenefitsClub)
