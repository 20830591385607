import React from 'react'
import { Image, Text, View } from 'react-native'
import { footerStyles } from './footer.styles'

const Footer = () => {
  return (
    <View style={footerStyles.container}>
      <View style={footerStyles.footerTop}>
        <Image
          source={require('../../assets/images/logo_grupo_defensa_blanco.png')}
          style={footerStyles.logo}
        />
        <View style={footerStyles.footerLinks}>
          {/*
          <Text style={footerStyles.link}>Ir a www.grupodefensa.cl</Text>
          <Text style={footerStyles.link}>Soluciones</Text>
          <Text style={footerStyles.link}>Nuestra experiencia</Text>
          <Text style={footerStyles.link}>Sucursales</Text>
          <Text style={footerStyles.link}>Publicaciones</Text>
          <Text style={footerStyles.link}>Contacto</Text>
          */}
          <Image
            source={require('../../assets/images/sello-40-horas.png')}
            style={footerStyles.logoRanked}
          />
          <Image
            source={require('../../assets/images/logo_leadersleague.png')}
            style={footerStyles.logoRanked}
          />
          
        </View>
      </View>
      <View style={footerStyles.bottomFooter}>
        <Text style={{ color: 'white' }}>
          Todos los derechos reservados por © Grupo Defensa, 2024
        </Text>
      </View>
    </View>
  )
}

export default Footer
