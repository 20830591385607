import React, { useState, useEffect } from 'react'
import { View, Button, Text, TouchableOpacity } from 'react-native'
import HeaderPageTitle from '../common/HeaderPageTitle'
import CustomTextInput from '../common/CustomTextInput'
import CustomPicker from '../common/CustomPicker'
import { generalStyles } from '../styles/general.styles'
import { myFilesStyles } from '../styles/myFiles.styles'
import { withRouter } from 'react-router-dom'
import AppointmentConfirmation from '../components/appointment-confirmation/AppointmentConfirmation'

const MyFiles = ({ history }) => {
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/iniciar-sesion') // Ajusta la ruta de inicio de sesión según tu configuración de navegación
    }
  }, [])

  const [data, setData] = useState({
    file: '',
    type: '',
  })

  const handleChange = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleSubmit = () => {
    console.log(data)
  }

  return (
    <View style={generalStyles.contentContainer}>
      <HeaderPageTitle title="Agendar hora" />
      <View style={myFilesStyles.form}>
        <CustomTextInput
          label="Seleccionar archivo"
          placeholder="Archivo"
          onChangeText={(value) => handleChange('type', value)}
          value={data.type}
        />

        <CustomPicker
          label="Tipo"
          options={['A', 'B', 'D']}
          onValueChange={(value) => handleChange('file', value)}
          value={data.file}
        />

        <TouchableOpacity style={myFilesStyles.button} onPress={handleSubmit}>
          <Text style={myFilesStyles.buttonText}>Enviar</Text>
        </TouchableOpacity>
      </View>
      <View>
        <AppointmentConfirmation />
      </View>
    </View>
  )
}

export default withRouter(MyFiles)
