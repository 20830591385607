// useAuthenticationCheck.js
import { useEffect } from 'react'

export const useAuthenticationCheck = (history) => {
  useEffect(() => {
    if (!localStorage.getItem('token')) {
      history.push('/iniciar-sesion')
    }
  }, [history])
}
