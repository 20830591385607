import dashAxios from './axios';
import axios from 'axios';

const askIA = async (prompt, fineTuning = '') => {
  try {
    const response = await axios.post('https://apiasac.defensaenlinea.cl/api/v1/chat/1', {
        user_query: prompt + fineTuning 
      }, {
        headers: {
          'Content-Type': 'application/json',
          'accept': 'application/json' 
        }
      });
    
    return {ok: true, response: response.data.message};
  } catch (error) {
    console.error('Error fetching completion from ChatGPT:', error);
    return null;
  }
};

export default askIA;
