import React, { useEffect, useState } from 'react'
import { View, Button, Text, TouchableOpacity, Platform } from 'react-native'
import HeaderPageTitle from '../../common/HeaderPageTitle'
import HeaderSubTitle from '../../common/HeaderSubTitle'
import { appointmentStyles } from './appoinment.styles'
import { generalStyles } from '../../styles/general.styles'
import { notify } from '../../utils/notify'
import { useAuthenticationCheck } from '../../hooks/useAuthenticationCheck'
import CustomInput from '../../common/CustomInput'
import { withRouter } from 'react-router-dom'
import DateInput from '../../common/DateInput'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import '../../styles/customDatePicker.css'
import { format } from 'date-fns'
import isMobile from '../../utils/dimensions'
import { sucursalAppointmentQuery, checkScheduleQuery } from '../../services/axios'
import AppointmentConfirmation from '../../components/appointment-confirmation/AppointmentConfirmation'
import Loading from '../../common/Loading'

const Appointment = ({ history }) => {
  useAuthenticationCheck(history)


  const [selectedDate, setSelectedDate] = useState(new Date())
  const [branches, setBranches] = useState([])
  const [revisarHora, setRevisarHora] = useState({ fecha: format(selectedDate, 'dd-MM-yyyy'), sucursal: "", tipo: 0 })
  const [formulario, setFormulario] = useState({ fecha: format(selectedDate, 'yyyy-MM-dd'), hora: null, sucursal: "", telefonoContacto: null, nombreAbogado: null, tipo: 0, abogadoId: null, sucursalId: null })
  const [horasDisponibles, setHorasDisponibles] = useState([])
  const [isDataValid, setIsDataValid] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [preReservation, setPreReservation] = useState()
  const [loading, setLoading] = useState(false);
  const [activeLink, setActiveLink] = useState('Remoto');
  const [fechaLabel, setFechaLabel] = useState("Fecha")
  const [horarioLabel, setHorarioLabel] = useState("Selecciona el horario para tu reunion")
  const [sucursalLabel, setSucursalLabel] = useState("Selecciona la sucursal para tu atención")
  const [titleLabel, setTitleLabel] = useState("Agenda una hora con tu abogado")
  
  
  
  useEffect(()=>{
    console.log(revisarHora)
  },[revisarHora])
  useEffect(()=>{
    console.log(formulario)
    if(formulario.fecha==null){ setIsDataValid(false); return;}
    if(formulario.hora==null){ setIsDataValid(false); return;}
    if(formulario.sucursalId==null){ setIsDataValid(false); return;}
    if(formulario.telefonoContacto==null){ setIsDataValid(false); return;}
    if(formulario.telefonoContacto!=null && formulario.telefonoContacto.length<8){ setIsDataValid(false); return;}

    
    
    setIsDataValid(true);
  },[formulario])

  useEffect(() => {
    const getSucursales = async () => {
      try {
        const response = await sucursalAppointmentQuery(
          '/Client/SucursalesAgenda',
          localStorage.getItem('token')
        )
        setBranches(response.data)
      } catch (error) {
        if(error.response.status==401){
          localStorage.removeItem('token')
          history.push('/iniciar-sesion')
        }else{
          notify('error', error)
        }
      }
    }
    getSucursales()
  }, [])

  const obtenerHoras = (sucursal, opcion) => {
    const getHoras = async () => {
      setLoading(true)
      try {
        revisarHora.sucursal = sucursal
        if(opcion!=null) revisarHora.tipo=opcion
        const response = await checkScheduleQuery( '/Client/VerificarAgenda', revisarHora, localStorage.getItem('token') )
        console.log(response.data)
      
        if (!response.data.tienehora) {
          setFormulario({...formulario, 
            nombreAbogado:response.data.horasPosibles.nombreAbogado,
            sucursalId: response.data.horasPosibles.sucursalId,
            sucursal: response.data.horasPosibles.sucursal,
            abogadoId: response.data.horasPosibles.abogadoId
          })
          
          var h1 = response.data.horasPosibles.mañana
          var h2 = response.data.horasPosibles.medioDia
          var h3 = response.data.horasPosibles.tarde
          setHorasDisponibles([...h1, ...h2, ...h3])
          if([...h1, ...h2, ...h3].length==0){
            notify('warning', "No hay horas disponibles para el modo y dia selecciondo")
          }
        }else{
          setPreReservation({ ...response.data.datosHoraCliente })
          setShowConfirmation(true)
        }
        setLoading(false)

      } catch (error) {
        alert(error.response.data.error)
        console.log(error)
        if(error.response.status==401){
          localStorage.removeItem('token')
          history.push('/iniciar-sesion')
        } else if(error.response.status==404){
          notify('warning', error.response.data.error)
          if(!error.response.data.exitoAgendado)
            notify('info', " Seleccione Otra Hora Por Favor")
        }else{
          notify('error', error)
        }
      }
    }
    getHoras()
  }

  const EnviarCita = () =>{
    const handleSubmitAppointment = async () => {

      const patt = /^[0-9]+$/;
      const fono = formulario.telefonoContacto
      if(fono.length>8){
        notify("warning","El telefono debe tener 8 digitos")
        return;
      }else if(!patt.test(fono)){
        notify("warning","El telefono debe ser numerico")
        return
      }

      try {
        const response = await checkScheduleQuery(
          '/Client/InsertarAgenda',
          formulario,
          localStorage.getItem('token')
        )
  
        if (response.status === 200) {
          notify('success', 'Se ha agendado la cita correctamente')
  
        }
      } catch (error) {
        if(error.response.status==401){
          localStorage.removeItem('token')
          history.push('/iniciar-sesion')
        }
        else if(error.response.status==404){
          notify('warning', error.response.data.error)
          if(!error.response.data.exitoAgendado)
            notify('info', " Seleccione Otra Hora Por Favor")
        }else{
          notify('error', error)
        }
      }
    }
    handleSubmitAppointment()
  } 

  const onLinkClick = (opcion) => {
    // opcion : remoto= 0, presencial=1
    setRevisarHora({...revisarHora, tipo:opcion})
    setFormulario({...formulario, tipo:opcion})
    if (opcion == 0) {
      setLoading(true)
      obtenerHoras(null, opcion)
      setSucursalLabel("Selecciona la sucursal para tu atención Remota")
      setHorarioLabel("Selecciona el horario para tu reunión remota con un abogado")
      setTitleLabel("Agenda un hora para atención remota con un abogado")
      setFechaLabel("Selecciona la fecha para tu atención remota")
    } else {
      setSucursalLabel("Selecciona la sucursal para tu atención presencial")
      setHorarioLabel("Selecciona el horario para tu reunión presencial con un abogado")
      setTitleLabel("Agenda una hora con tu abogado")
      setFechaLabel("Selecciona la fecha para tu atención presencial")
    }
  }
  
  return (
    <View style={ isMobile() ? generalStyles.contentContainerResponse : generalStyles.contentContainer } >
      {showConfirmation?<AppointmentConfirmation data={preReservation} ocultar={()=>setShowConfirmation(false)}  />
      :null}
      <HeaderPageTitle title={titleLabel}/>

      <View style={appointmentStyles.form}>

        <DatePicker selected={selectedDate}
          onChange={(date) => {
            setSelectedDate(date)
            setRevisarHora({...revisarHora, fecha:format(date, 'dd-MM-yyyy')})
            setFormulario({...formulario, fecha:format(date, 'yyyy-MM-dd')})
          }}
          value={format(selectedDate, 'dd-MM-yyyy')}
          calendarClassName="custom-calendar"
          customInput={<DateInput Fdate={format(selectedDate, 'dd-MM-yyyy')} Flabel={fechaLabel}/>}
          dayClassName={() => 'custom-day'}
          dateFormat="dd-MM-yyyy"
        />

        <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
          <TouchableOpacity style={{backgroundColor: '#D38743', padding: 10, margin: 5, borderRadius: 5}} onPress={() => onLinkClick(0)} >
            <Text style={{color:'white'}}> Buscar Hora para atencion Remota </Text>
          </TouchableOpacity>
          <TouchableOpacity style={{backgroundColor: '#4396D3', padding: 10, margin: 5, borderRadius: 5}} onPress={() => onLinkClick(1)} >
            <Text style={{color:'white'}}> Buscar Hora para atencion  Presencial </Text>
          </TouchableOpacity>
        </View>

          
        {/*<CustomInput label="Modalidad de atención"
          options={['Remoto', 'Presencial']}
          onChange={(e)=>{
            setRevisarHora({...revisarHora, tipo:( e=='Remoto'? 0 : 1 )})
            setFormulario({...formulario, tipo:( e=='Remoto'? 0 : 1 )})
            if(e=='Remoto')
            setTimeout(() => {
              obtenerHoras()
            }, 500);
          }}
          type="picker"
        /> */}

        {loading && ( <Loading />)}

        {revisarHora.tipo==1 ?
          <CustomInput label={sucursalLabel}
          options={branches}
          onChange={(e)=>{
              setRevisarHora({...revisarHora, sucursal:e})
              setFormulario({...formulario, sucursal:e})
              obtenerHoras(e,null) } }
          type="picker"
        />:null}

        {horasDisponibles.length>0 ?<>
          <CustomInput
            label={horarioLabel}
            placeholder="00:00"
            options={horasDisponibles}
            onChange={(e) =>{
              setFormulario({...formulario, hora:e})
            }}
            type="picker"
          />
        
          <CustomInput
              label="Teléfono de contacto"
              placeholder="Teléfono de contacto"
              onChange={(e)=>{
                  setFormulario({...formulario, telefonoContacto:e})
              }}
              type="phone"
            />
          <View style={{width:'100%', alignItems:'center'}}>
            <HeaderPageTitle title= {"Abogado Designado"}/>
            <HeaderSubTitle title={formulario.nombreAbogado}/>
          </View>
          </>:null}



        <Text>
          Importante: <span style={appointmentStyles.span}>*</span> Campos
          obligatorios
        </Text>
        <View style={appointmentStyles.buttonsContainer}>
          <TouchableOpacity
            style={[
              appointmentStyles.button,
              { backgroundColor: isDataValid ? '#00cc00' : '#cccccc' },
            ]}
            onPress={()=>EnviarCita()}
            disabled={!isDataValid}
          >
            <Text style={appointmentStyles.buttonText}>Enviar</Text>
          </TouchableOpacity>
        </View>
      </View>
      

    
    </View>
  )
}

export default withRouter(Appointment)
