import { StyleSheet } from 'react-native'

export const customPickerStyles = StyleSheet.create({
  form: {
    padding: 20,
  },
  select: {
    height: 40,
    width: '100%',
    borderRadius: 5,
    paddingLeft: 10,
    marginBottom: 20,
  },
  label: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: 'bold',
    color: '#0076b4',
  },
})
