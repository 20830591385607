import { StyleSheet } from 'react-native'
import isMobile from '../../utils/dimensions'


const homeStyles = StyleSheet.create({
  cardDocs:{
    flex:1,
    width: 300,
    height:150,
    marginHorizontal:30,
    marginVertical:10,
    borderRadius: '5px',
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingHorizontal: 20,
    shadowColor: '#171717',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    borderWidth: 1,
    borderColor: '#f0eded',
  },

  card:{
    flex:1,
    width: 250,
    height:'100%',
    marginHorizontal:30 ,
    borderRadius: '5px',
    backgroundColor: 'white',
    justifyContent: 'center',
    paddingHorizontal: 20,
    shadowColor: '#171717',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    borderWidth: 1,
    borderColor: '#f0eded',
    alignContent:'center',
    alignItems:'center',
    flexDirection: 'center'

  },
  contentDocuments:{
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems:'center',
    width:'100%',
    height:'auto',
    zIndex:1000,
    marginTop:320,
    position:'absolute'
  },
  contentDocs:{
    flexDirection: 'row',
    width:'100%',
    height:'auto',
    zIndex:1000,
    marginTop:550,
    position:'absolute'
  },
  contentCard: {
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems:'center',
    width:'100%',
    height:'150px',
    zIndex:1000,
    marginTop:550,
    position:'absolute'
  },
  imageWhatsApp:{
    width:50, 
    height:50, 
    marginRight: 15,
    shadowColor: '#171717',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.5,
    shadowRadius: 3,
  },
  container: {
    flex: 1,
    width:'100%',
    backgroundColor:'#ececec',
    alignItems: 'center'
  },
  textBanner: {
    position:'absolute',
    marginTop:100,
    marginLeft:100,
    width:'35%',
    zIndex:999
  },
  imageBanner: {
    width: 'content',
    height: 500
  },
  bottomText: {
    width: '100%',
    textAlign:'end',
    color: "#4650DD",
    fontWeight:'bold',
    marginTop:11
  },
  rowContainer:{

    flexDirection: 'row',
    justifyContent: 'space-between',
  },




  form: {
    borderRadius: 10,
    backgroundColor: 'white',
    justifyContent: 'center',
    width: '35%',
    height: '100%',
    paddingHorizontal: 30,
    flexDirection: 'column',
    shadowColor: '#171717',
    shadowOffset: { width: 2, height: 2 },
    shadowOpacity: 0.1,
    shadowRadius: 3,
    borderWidth: 1,
    borderColor: '#f0eded',
  },

  label: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: 'bold',
    success: {
      color: '#0076b4',
    },
    error: {
      color: 'red',
    },
  },
  logoImage: {
    width: '90%',
    height: '90%',
    alignSelf: 'center'
  },
  banner: {
    width: '100%',
    height: 150,
    marginBottom: 20,
    backgroundColor: '#0076b4',
    position: 'absolute',
    top: 0,
  },
  input: {
    height: 40,
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    marginBottom: 25,
    paddingLeft: 10,
  },
  errorInput: {
    borderColor: 'red',
    borderWidth: 1,
  },
  errorText: {
    color: 'red',
  },
  title: {
    fontSize: 30,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  forgotPasswordText: {
    marginTop: 10,
    color: '#69A62D',
    textAlign: 'right',
    fontWeight: 'bold',
    marginTop: 20,
  },
  imageContainer: {
    alignItems: 'center',
    marginBottom: 15,
  },
  loading: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(0,0,0,0.2)', // Puedes ajustar el color y la opacidad
  }
})



export default homeStyles