import { StyleSheet } from 'react-native'

export const appointmentStyles = StyleSheet.create({
  form: {
    padding: 20,
  },
  input: {
    height: 40,
    borderRadius: 5,
    marginBottom: 20,
    paddingLeft: 10,
    backgroundColor: '#fff',
  },
  select: {
    height: 40,
    width: '100%',
    borderRadius: 5,
    border: '1px solid #ccc',
    paddingLeft: 10,
    marginBottom: 20,
  },
  label: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: 'bold',
    color: '#0076b4',
  },
  span: {
    color: 'red',
  },
  button: {
    width: 120,
    height: 50,
    textAlign: 'center',
    marginLeft: 10,
    backgroundColor: '#69A62D',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-end', // Esto hace que el botón se alinee a la derecha del contenedor padre
    borderRadius: 20, // Añade un poco de borde redondeado
    padding: 10, // Añade un poco de padding
  },
  buttonText: {
    color: 'white',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    flexDirection: 'row',
  },
})
