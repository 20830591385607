import { StyleSheet } from 'react-native'

export const generalStyles = StyleSheet.create({
  body: {
    fontFamily: 'Helvetica ',
  },
  generalContainer: {
    width: '100%',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    //paddingHorizontal: 0,
    //paddingRight: 20,
    minHeight: 760,
  },
  navigationContainer: {
    //backgroundColor: '#e5e5e5',
    width: '25%',
    display: 'flex',
    maxWidth: 176,
    shadowOffset: {width: -2, height: 3},  
    shadowColor: '#000',  
    shadowOpacity: 0.15,  
    shadowRadius: 20
  },
  navigationContainerResponsive: {
    backgroundColor: '#fff',
    width: '100%',
    display: 'flex',
    //maxWidth: 176,
    shadowOffset: {width: -2, height: 3},  
    shadowColor: '#000',  
    shadowOpacity: 0.15,  
    shadowRadius: 20,
    position: 'absolute',
    zIndex: 99999
  },
  contentContainer: {
    paddingLeft: 30,
    paddingTop: 30,
    width: '75%'
  },
  contentContainerResponse: {
    paddingLeft: 10,
    paddingTop: 30,
    width: '100%'
  },
  container: {
    width: '200px',
  },
  itemsContainer: {
    width: '100%',
    border: '1px solid red',
  },
  headerTitle: {
    textTransform: 'uppercase',
    color: '#999',
    fontSize: 18,
    fontWeight: 'bold',
    margin: 15,
    //backgroundColor: '#69A62D',
    //padding: 10,
    //borderRadius: 15,
  },
  headerSubTitle: {
    textTransform: 'uppercase',
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold',
    margin: 15,
    marginTop: -10,
    //backgroundColor: '#69A62D',
    //padding: 10,
    //borderRadius: 15,
  },
  span: {
    color: 'red',
  },
})
