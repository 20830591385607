import React, { useState } from 'react'
import { View, Text, Image, TouchableOpacity } from 'react-native'
import { benefitsCardStyles } from './benefits-card.styles'
import { withRouter } from 'react-router-dom'

const BenefitsCard = ({ data, history }) => {
  const [activeLink, setActiveLink] = useState('')
  const onLinkClick = (link) => {
    setActiveLink(link)
    if (link.startsWith('http')) {
      // Redirige a una URL externa abriendo una nueva pestaña del navegador
      window.open(link, '_blank')
    } else {
      // Redirige a una ruta interna del sitio
      history.push(link)
    }
  }
  const { titulo, mensaje, imagen, vencimiento, condiciones } = data

  return (
    <View style={benefitsCardStyles.container}>
      <Image source={{ uri: imagen }} style={benefitsCardStyles.image} />
      <View style={benefitsCardStyles.contentContainer}>
        <Text style={benefitsCardStyles.title}>{titulo}</Text>
        <Text style={benefitsCardStyles.text}>{mensaje}</Text>
       {/*
        <Text style={benefitsCardStyles.date}>
          Fecha de vencimiento: {vencimiento}
        </Text>
       
        <Text style={benefitsCardStyles.terms}>
          Acepto los{' '}
          <TouchableOpacity onPress={() => onLinkClick(condiciones)}>
            <Text style={benefitsCardStyles.termsLink}>
              términos y condiciones aquí
            </Text>
          </TouchableOpacity>
          .
        </Text>
       */}
      </View>
    </View>
  )
}

export default withRouter(BenefitsCard)
