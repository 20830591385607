import React, { useEffect, useState } from 'react'
import { View, Text, TextInput } from 'react-native'
import { customTextInputStyles } from '../styles/customTextInput.styles'

const CustomTextInput = ({ label, placeholder, onChangeText, value, obligatorio, type, 
  styleMisDatos }) => {
  const [showPhone, setShowPhone]=useState(false)
  useEffect(()=>{
    if(type!=null && type=='phone')
      setShowPhone(true)
  },[])
  return (
    <View>
      <Text
        style={customTextInputStyles.label}
        aria-label={label}
        nativeID={label}
      >
        {label} {obligatorio ? <span style={customTextInputStyles.span}>*</span>:null}
      </Text>
      <View style={{ flexDirection: 'row' }}>
      {showPhone ?
        <Text style={{fontWeight:'bold', color: "#0076C5", fontSize:17, padding:7}}>+569{' '}</Text>
      :null }
      <TextInput
        aria-labelledby={label}
        style={[customTextInputStyles.input,(styleMisDatos ? styleMisDatos: null)]}
        onChangeText={onChangeText}
        value={value}
        placeholder={placeholder}
      />
      </View>
    </View>
  )
}

export default CustomTextInput
