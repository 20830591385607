import { useEffect, useState } from 'react'
import { View, Text, TouchableOpacity, Button } from 'react-native'
import HeaderPageTitle from '../common/HeaderPageTitle'
import CustomTextInput from '../common/CustomTextInput'
import { appointmentStyles } from './appointment/appoinment.styles'
import { generalStyles } from '../styles/general.styles'
import { resetPasswordQuery, updateProfileQuery, getProfileQuery } from '../services/axios'
import { notify } from '../utils/notify'
import { withRouter } from 'react-router-dom'
import { isValidData } from '../utils/isValidData'
import { isMobile } from '../utils/dimensions'
import UpdatePassword from './reset-password/UpdatePassword'
import { resetPasswordStyles } from './reset-password/reset-password.styles'

/**
 * Componente de perfil de usuario.
 * Permite ver y editar los datos de un perfil de usuario.
 */
const Profile = ({ history }) => {

  const [data, setData] = useState({ email: '', telephone: '', telephone2: '', address: '', })
  const [isDataValid, setIsDataValid] = useState(false)
  const [resetPassword, setResetPassword] = useState({ rut: '', newPass: '', updaterId: 0, })

  
  if(localStorage.getItem('token')==null){
    localStorage.removeItem('token')
    history.push('/iniciar-sesion')
    return;
  }
  
  useEffect(()=>{
    setIsDataValid(false)
    if(data.email!='' && data.telephone!='')
      setIsDataValid(true)
  },[data])

  useEffect(()=>{
    
    const getProfile = async () => {
      
    try {
      const response = await getProfileQuery('/Client/InfoCliente', localStorage.getItem('token'))

      if(response.data){
        setData({
          email: response.data.correo?response.data.correo:'',
          telephone: response.data.telefonoPrincipal ? response.data.telefonoPrincipal:'',
          telephone2: response.data.telefonoSecundario ? response.data.telefonoSecundario:'',
          address: response.data.direccion?response.data.direccion:'',
        })
      }
    } catch (error) {
      if(error.response.status==401){
        localStorage.removeItem('token')
        history.push('/iniciar-sesion')
      }else{
        notify('error', 'Clave o usuario incorrecto')
      }

    }

    }
    getProfile()


  },[])
  

  /**
   * Maneja el cambio de valores en los campos de datos del perfil.
   * @param {string} name - Nombre del campo.
   * @param {string} value - Nuevo valor del campo.
   */
  const handleChange = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  /**
   * Maneja el cambio de valores en los campos de contraseña.
   * @param {string} name - Nombre del campo.
   * @param {string} value - Nuevo valor del campo.
   */
  const handlePasswordChange = (name, value) => {
    setResetPassword((prevState) => ({
      ...prevState,
      [name]: value,
    }))
  }

  /**
   * Actualiza la contraseña del usuario.
   */
  const handlePasswordSubmit = async () => {
    try {
      await resetPasswordQuery(
        '/Client/UpdatePassword',
        resetPassword,
        localStorage.getItem('token')
      )

      notify(
        'success',
        '¡Contraseña actualizada correctamente! Vuelva a iniciar sesión',
        () => {
          localStorage.clear()
          history.push('/iniciar-sesion')
        }
      )
    } catch (error) {
      const err = error.response.data.error
      notify('error', err)
    }
  }

  /**
   * Actualiza los datos del perfil del usuario.
   */
  const handleSubmit = async () => {
    const patt = /^[0-9]+$/;
    const fono = data.telephone
    if(fono.length!=8){
      notify("warning","El telefono debe tener 8 digitos1")
      return;
    }else if(!patt.test(fono)){
      notify("warning","El telefono debe ser numerico2")
      return
    }
    try {
      await updateProfileQuery(
        '/Client/Update',
        data,
        localStorage.getItem('token')
      )

      notify('success', 'Datos cambiados correctamente')

    } catch (error) {
      const err = error.response.data.error
      notify('error', err)
    }
  } 

  return (
    <View style={[{padding:20},resetPasswordStyles.container,{width:'100%', alignItems:'center'}]}>
      <HeaderPageTitle  title="Mis datos" />
      <View style={[resetPasswordStyles.form]}>
        <CustomTextInput
          label="Correo electrónico"
          placeholder="Correo electrónico"
          onChangeText={(value) => handleChange('email', value)}
          value={data.email}
          obligatorio={true}
          styleMisDatos={{width:'100%'}}
        />
        <CustomTextInput
          label="Teléfono principal"
          placeholder="Teléfono principal"
          onChangeText={(value) => handleChange('telephone', value)}
          value={data.telephone}
          obligatorio={true}
          styleMisDatos={{width:'100%'}}
          type="phone"
        />
        <CustomTextInput
          label="Teléfono secundario"
          placeholder="Teléfono secundario"
          onChangeText={(value) => handleChange('telephone2', value)}
          value={data.telephone2}
          obligatorio={false}
          styleMisDatos={{width:'100%'}}
          type="text"
        />
        <CustomTextInput
          label="Dirección"
          placeholder="Dirección"
          onChangeText={(value) => handleChange('address', value)}
          value={data.address}
          obligatorio={false}
          styleMisDatos={{width:'100%'}}
        />
        <Text style={{marginBottom:5}}>
          Importante: <Text style={appointmentStyles.span}>*</Text> Campos
          obligatorios
        </Text>


        { isDataValid?
          <Button title="ACTUALIZAR MIS DATOS" onPress={handleSubmit} />
        :
          <TouchableOpacity style={{width:'100%', padding:8, backgroundColor: '#cccccc' }} >
            <Text style={[appointmentStyles.buttonText,{fontWeight:'BOLD', fontSize:13 ,textAlign:'center', width:'100%'}]}>ACTUALIZAR MIS DATOS</Text>
          </TouchableOpacity>
        }

      </View>

      <UpdatePassword Profile={true}/>

    </View>
  )
}

export default withRouter(Profile)
