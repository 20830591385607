import { StyleSheet } from 'react-native'

export const NavigationWebStyles = StyleSheet.create({
  button: {
    margin: 1,
    //backgroundColor: '#fcfcfc',
    padding: 15,
    paddingLeft: 0,
    //borderRadius: 15,
    textDecorationLine: 'none',
  },
  buttonActive: {
    margin: 1,
    //backgroundColor: '#0076b4',
    padding: 15,
    paddingLeft: 0,
    //borderRadius: 15,
    textDecorationLine: 'none',
  },
  buttonActiveText: {
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: 12,
    color: '#0076b4',
    textTransform: 'uppercase'
  },
  
  buttonText: {
    fontFamily: 'sans-serif',
    fontWeight: 'bold',
    fontSize: 11,
    color: '#464646',
    textTransform: 'uppercase'
  },
  
  navigationContainer: {
        //backgroundColor: '#e5e5e5',
        width: '100%',
        height: 100,
        display: 'flex',
        shadowOffset: {width: 0, height: 1},  
        borderBottomEndRadius: '1px',
      },

  body: {
    fontFamily: 'Helvetica ',
  },
  generalContainer: {
    width: '100%',
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    //paddingHorizontal: 0,
    //paddingRight: 20,
    minHeight: 760,
  },
  
  navigationContainerResponsive: {
    backgroundColor: '#fff',
    width: '100%',
    display: 'flex',
    //maxWidth: 176,
    shadowOffset: {width: -2, height: 3},  
    shadowColor: '#000',  
    shadowOpacity: 0.15,  
    shadowRadius: 20,
    position: 'absolute',
    zIndex: 99999
  },
  contentContainer: {
    paddingLeft: 30,
    paddingTop: 30,
    width: '75%'
  },
  contentContainerResponse: {
    paddingLeft: 10,
    paddingTop: 30,
    width: '100%'
  },
  container: {
    width: '200px',
  },
  itemsContainer: {
    width: '100%',
    border: '1px solid red',
  },
  headerTitle: {
    textTransform: 'uppercase',
    color: '#999',
    fontSize: 18,
    fontWeight: 'bold',
    margin: 15,
    //backgroundColor: '#69A62D',
    //padding: 10,
    //borderRadius: 15,
  },
  headerSubTitle: {
    textTransform: 'uppercase',
    color: '#000',
    fontSize: 16,
    fontWeight: 'bold',
    margin: 15,
    marginTop: -10,
    //backgroundColor: '#69A62D',
    //padding: 10,
    //borderRadius: 15,
  },
  span: {
    color: 'red',
  },
})
