import React, { useState, useEffect } from 'react'
import { View, Button, TouchableOpacity, Text, TextInput, Image, FlatList} from 'react-native'
import Loading from '../../common/Loading'
import { withRouter } from 'react-router-dom' // Importa withRouter
import homeStyles from './home.styles' 
import {postNoDataQuery} from '../../services/axios'

import { notify } from '../../utils/notify'


const Documents = ({ history }) => {
    const [nombreCliente,setNombreCliente] = useState();
    const [showDocs,setshowDocs] = useState(false);
    const [litsDocs,setLitsDocs] = useState([]);
    
    useEffect(()=>{
        const info = JSON.parse(localStorage.getItem('informacionCliente'))
        setNombreCliente(info.name.charAt(0).toUpperCase() + info.name.slice(1))
        getDocs()
    },[])

    const getDocs = async () => {
        try {
            const response = await postNoDataQuery( '/Client/Contratos', localStorage.getItem('token') )
            if(response.data.length>0){
                setshowDocs(true)
                setLitsDocs(response.data)
            }
        } catch (error) {
            console.log(error)
            if(error.response.status==401){
            localStorage.removeItem('token')
            history.push('/iniciar-sesion')
            }else{
            const err = error.response
            notify('error', err)
            }
        }
    }

      
 
  return (
    <View style={homeStyles.container}>
        <View style={{width:'100%'}}>
            <View style={homeStyles.textBanner}>
                <Text style={{fontSize:30, color: 'white', fontWeight:'bold', marginBottom:10}}>{nombreCliente} estos son tus documentos</Text>
                <Text style={{fontSize:16, color: 'white', fontWeight:'bold', marginBottom:5}}>ESTAS EN TU NUEVA SUCURSAL VIRTUAL.</Text>
                <Text style={{fontSize:16, color: 'white', fontWeight:'bold', marginBottom:5}}>Busca las novedades de tus causas, actualiza tu información personal y si necesitas mas atención, no dudes en agendar una visita o contactarte via WhatsApp.</Text>
            </View>
            <Image source={require('../../assets/images/banner.jpg')} style={homeStyles.imageBanner} />
        </View>
        
        <FlatList
            style={homeStyles.contentDocs}
            data={litsDocs}
            numColumns={4} // Establece el número de columnas a 3
            keyExtractor={(item) => item.id}
            renderItem={({ item }) => (
                <TouchableOpacity style={homeStyles.cardDocs} onPress={()=>window.open(item.urlContrato, '_blank')}>
                    <View style={homeStyles.rowContainer}>
                        <Image style={homeStyles.imageWhatsApp} source={require('../../assets/icons/libros.png')}/>
                        <Text style={{fontSize:15, color: 'BLACK', fontWeight:'bold', marginBottom:5}}>OBTENER MI CONTRATO</Text>
                    </View> 
                    <Text style={homeStyles.bottomText}>Descargar contrato</Text>
                </TouchableOpacity>
            )}
            />
    </View>
  )
}

export default withRouter(Documents) // Envuelve el componente Login con withRouter
