import React, { useEffect, useState } from 'react'
import { View, Button, TouchableOpacity, Text, Image, Modal, ScrollView } from 'react-native'
import { Formik, Field, ErrorMessage } from 'formik'
import { withRouter } from 'react-router-dom' // Importa withRouter
import { resetPasswordStyles } from './reset-password.styles'
import { resetPasswordOption } from '../../services/axios'
import { styles as stylesModal } from '../../styles/modalMovements.styles'

const OptionsResetPassword = ({ history }) => {
  const [error, setError] = useState('')  
  const [optionSelect, setOptionsSelect] = useState(0)
  const [optionView, setOptionsView] = useState([])
  const [modalVisible, setModalVisible] = useState(false)

  const handleResetPasswordSelected = async (optionselected) => {
    try {
      const response = await resetPasswordOption('/SucursalVirtual/CodigoVerificacion/'+optionselected, {}, localStorage.getItem('token'))
      const { data } = response.data;
      // SI ES OK ENVIAR A DIGITAR 
      history.push('/olvide-mi-contrasena-digitar')
    } catch (error) {
      console.log(error)
      setError('Clave o usuario incorrecto') // Establece el mensaje de error
    }
  }
  useEffect(()=>{
    setModalVisible(true)
  },[])

  useEffect(() => {
    var options = JSON.parse(localStorage.getItem('optionsrecover'))
    var options = options.options;
    var op=[]
    if(options[0].length>0){
      op.push(<>
        <Text style={[ [ resetPasswordStyles.label.success, resetPasswordStyles.label, {textAlign:'center'} ] ]} >
          Al Correo:
        </Text>
        <Text style={[ [ resetPasswordStyles.label.success, resetPasswordStyles.label, {textAlign:'center'} ] ]} >
          {options[0]}
        </Text>
        <Button title="Enviar via correo" onPress={()=>handleResetPasswordSelected(1)} />
        </>)
    }
    if(options[1].length>0){
      op.push(<>
        <Text style={[ [ resetPasswordStyles.label.success, resetPasswordStyles.label, {marginTop:20, textAlign:'center'} ] ]} >
          Al Telefono:
        </Text>
        <Text style={[ [ resetPasswordStyles.label.success, resetPasswordStyles.label, {textAlign:'center'} ] ]} >
          {options[1]}
        </Text>
      <Button title="Enviar via Telefono" onPress={()=>handleResetPasswordSelected(2)} />
      </>)
    }
    if(options[2].length>0){
      op.push(<>
        <Text style={[ [ resetPasswordStyles.label.success, resetPasswordStyles.label, {marginTop:20, textAlign:'center'} ] ]} >
          Al Telefono:
        </Text>
        <Text style={[ [ resetPasswordStyles.label.success, resetPasswordStyles.label, {textAlign:'center'} ] ]} >
          {options[2]}
        </Text>
      <Button title="Enviar via Telefono" onPress={()=>handleResetPasswordSelected(3)} />
      </>)
    }
    setOptionsView(op)
  },[]);

  return (
    <>
    <Modal
        animationType="slide"
        transparent={true}
        visible={/*modalVisible*/false}
        onRequestClose={() => {
          setModalVisible(!modalVisible)
        }}
      >
        <View style={stylesModal.centeredView}>
          <View style={ { ...{width:'75%', maxWidth: 600}, ...stylesModal.modalView }}>
            <ScrollView style={{maxHeight: "600px"}}>
              <Text style={{fontWeight:'bold', fontSize:18, marginBottom: 10, textAlign:'center', color: '#838383'}}> Bienvenido a tu nueva sucursal virtual </Text>
              <Text style={{fontWeight:'bold', fontSize:15, textAlign:'center', color: '#000',marginBottom: 15}}>Para iniciar por primera siga los siguientes pasos: </Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>1- Ingrese su Rut y presion RECUPERAR CONTRASEÑA </Text>
               <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>3- Ingrese este código de 6 números para crear su nueva clave.</Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5}}>4- Su clave debe cumplir las siguientes características:</Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>a- Mínimo 6 caracteres</Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>b- Al menos una letra mayúscula</Text>
              <Text style={{fontWeight:'italic', fontSize:15, color: '#838383', textAlign:'justify', marginBottom: 5, paddingLeft: 20}}>c- Al menos un número.</Text>
             
            </ScrollView>
            <Button style={{marginTop: '15px'}} title="Cerrar" onPress={() => setModalVisible(false)} />
          </View>
        </View>
      </Modal>
    <Formik>
        <View style={resetPasswordStyles.container}>
          <View style={[resetPasswordStyles.banner]}></View>
          <View style={resetPasswordStyles.form}>
            <View style={{ alignItems: 'center' }}>
              <Image
                style={resetPasswordStyles.logoImage}
                source={require('../../assets/images/logo_grupodefensa_full.png')}
              />
            </View>
            
            <Text  style={{fontSize: 20, marginBottom: 15, textAlign:'center', fontWeight:'bold', color: '#838383' }}>Enviar codigo de confimacion</Text>

             
            {optionView.length>0 ?<>
              <Text style={[ [ resetPasswordStyles.label.success, resetPasswordStyles.label, {textAlign:'center'} ] ]} > Se enviará un código de 6 números </Text>
              {optionView}
              <Text style={{fontWeight:'bold', fontSize:15, color: '#C54848', textAlign:'center', marginBottom: 5, marginTop: 15}}>Si el o los datos de contacto son incorrectos comuniquese al <br/> 600-571-9300 (opcion 2).</Text>
             </>
                : <>
              <Text style={{fontWeight:'bold', fontSize:15, color: '#C02828', textAlign:'center', marginBottom: 5}}>
                Ups, no tenemos registrado tus datos de contacto.
                <br/>Favor comuniquese al <br/>600-571-9300  (opcion 2).
              </Text>
            
              </>
              }

          <TouchableOpacity onPress={() => history.push('/iniciar-sesion')}>
              <Text style={resetPasswordStyles.forgotPasswordText}>
                Ir a iniciar sesión
              </Text>
            </TouchableOpacity>
          </View>
        </View>
    </Formik>
    </>
  )
}

export default withRouter(OptionsResetPassword) // Envuelve el componente Login con withRouter
