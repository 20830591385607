import { StyleSheet } from 'react-native'

export const checkingStyles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: 'black',
    padding: 20,
    textAlign: 'center',
  },
  headerCell: {
    //flex: 1,
    fontWeight: 'bold',
    paddingVertical: 5,
    width: 30,
    color: 'rgb(0, 118, 180)',
  },
  cell: {
    //flex: 1,
    paddingVertical: 5,
    textAlign: 'center',
  },
  paymentOption: {
    width: 15,
    height: 15,
    borderRadius: 40,
    borderWidth: 2,
    backgroundColor: 'white',
  },
  selectedPaymentOption: {
    borderColor: 'green',
    backgroundColor: 'green',
  },
  paymentOptionText: {
    fontSize: 16,
  },
})
