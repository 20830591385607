import { StyleSheet } from 'react-native'

export const myFilesStyles = StyleSheet.create({
  form: {
    padding: 20,
    // display: 'flex',
    // justifyContent: 'space-between',
    // flexDirection: 'row',
  },
  label: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: 'bold',
    color: '#0076b4',
  },
  span: {
    color: 'red',
  },
  button: {
    width: 100,
    backgroundColor: '#0076b4',
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'flex-start', // Esto hace que el botón se alinee a la derecha del contenedor padre
    borderRadius: 20, // Añade un poco de borde redondeado
    padding: 10, // Añade un poco de padding
  },
  buttonText: {
    color: 'white',
  },
})
