// CustomInput.js
import React from 'react'
import CustomTextInput from './CustomTextInput'
import CustomPicker from './CustomPicker'

const CustomInput = ({
  label,
  placeholder,
  onChange,
  value,
  options,
  type,
}) => {
  if (type === 'text') {
    return (
      <CustomTextInput
        label={label}
        placeholder={placeholder}
        onChangeText={onChange}
        value={value}
      />
    )
  }

  if (type === 'picker') {
    return (
      <CustomPicker
        label={label}
        options={options}
        onValueChange={onChange}
        value={value}
      />
    )
  }
  if (type ==='phone'){
    return (
      <CustomTextInput
        type={type}
        label={label}
        placeholder={placeholder}
        onChangeText={onChange}
        value={value}
      />
    )
  }
}

export default CustomInput
