import React, { useState } from 'react'
import { Text, View } from 'react-native'
import { Picker } from '@react-native-picker/picker'
import { customPickerStyles } from '../styles/customPicker.styles'
import { generalStyles } from '../styles/general.styles'

/**
 * @component
 *
 * @param {string} label - Etiqueta del selector. Esta etiqueta será visible para el usuario.
 *
 * @param {Array<string>} options - Un array de opciones que el usuario puede seleccionar en el selector.
 *
 * @param {Function} onValueChange - Función que se llama cuando el valor seleccionado cambia.
 *                                   Debe aceptar un único argumento que es el nuevo valor seleccionado.
 * @param {string} value - El valor actualmente seleccionado en el selector.
 *
 * @returns Un componente Picker con opciones personalizadas basado en los props pasados.
 */
const CustomPicker = ({ label, options, onValueChange, value }) => {
  const [selectedValue, setSelectedValue] = useState(value)

  const handleValueChange = (itemValue) => {
    setSelectedValue(itemValue)
    onValueChange(itemValue)
  }

  return (
    <>
      <Text
        style={customPickerStyles.label}
        aria-label={label}
        nativeID={label}
      >
        {label} <span style={generalStyles.span}>*</span>
      </Text>
      <View>
        <Picker
          selectedValue={selectedValue}
          onValueChange={handleValueChange}
          mode="dropdown"
          style={customPickerStyles.select}
        >
          <Picker.Item label="Seleccione una opción" value="" />
          {options.map((option, index) => (
            <Picker.Item key={index} label={option} value={option} />
          ))}
        </Picker>
      </View>
    </>
  )
}

export default CustomPicker
