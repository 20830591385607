import { StyleSheet } from 'react-native'

const headerStyles = StyleSheet.create({
 

  logout: {
    backgroundColor: '#4650dd',
    width: 80,
    height: 30,
    borderRadius: 3,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
  },
  headerContainer: {
    height: 65,
    shadowOffset: {width: -2, height: 3},  
    shadowColor: '#000',  
    shadowOpacity: 0.15,  
    shadowRadius: 20
    //,marginBottom: 30

  },
  leftContainer: {
    paddingLeft: 20,
    height: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  centerContainer:{
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  rightContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  logo: {
    marginTop: 10,
    width: 144,
    height: 40,
    resizeMode: 'contain',
  },
  iconMenu: {
    marginTop: 10,
    width: 40,
    height: 40,
    resizeMode: 'contain',
  },
  button: {
    backgroundColor: 'white',
    borderRadius: 5,
    paddingHorizontal: 10,
    paddingVertical: 5,
    marginRight: 10,
    paddingLeft: 50,
  },
  buttonText: {
    color: 'black',
    fontSize: 16,
    fontWeight: 'bold',
  },
  socialButtonsContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
  }
})

export default headerStyles
